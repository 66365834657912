import {
  Component,
  NgZone,
  OnInit,
  ElementRef,
  ViewChild,
  Injectable,
} from '@angular/core';
import {
  title,
  button,
  ERROR_CODE,
  MESSAGE_CODE,
  heading_title,
  validation_message,
  status,
  menu_title,
  error_message,
  success_message,
  placeholder,
  label,
  text,
} from './string';
import { POST_METHOD, GET_METHOD } from './http_methods';
import { WebsiteData } from './website_data';
import { CommonData } from './common_data';
import {
  ORDER_STATUS_ID,
  ORDER_STATE,
  PAYMENT_GATEWAY,
  ADMIN_DATA_ID,
  WEEK_DAY,
} from './constant';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from './loader.service';
import { country_list } from './constant';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class Helper {
  base_url = environment.apiUrl;
  image_base_url = environment.imageUrl;

  ///// for constant ////////////
  public title: any = title;
  public button: any = button;
  public heading_title: any = heading_title;
  public status: any = status;
  public menu_title: any = menu_title;
  public placeholder: any = placeholder;
  public label: any = label;
  public text: any = text;
  public validation_message: any = validation_message;
  public error_message: any = error_message;
  public success_message: any = success_message;
  public POST_METHOD: any = POST_METHOD;
  public ERROR_CODE: any = ERROR_CODE;
  public MESSAGE_CODE: any = MESSAGE_CODE;
  public GET_METHOD: any = GET_METHOD;
  public ORDER_STATUS_ID: any = ORDER_STATUS_ID;
  public PAYMENT_GATEWAY_CONSTANT: any = PAYMENT_GATEWAY;
  public ADMIN_DATA_ID: any = ADMIN_DATA_ID;
  public WEEK_DAY: any = WEEK_DAY;
  public ORDER_STATE: any = ORDER_STATE;
  public country_list: any = country_list;
  public modal_reference: any;
  public api_call_time: any = Date.now();

  constructor(
    public loader_service: LoaderService,
    public common_data: CommonData,
    public website_data: WebsiteData,
    public trans: TranslateService,
    private toastr: ToastrService,
    public http: HttpClient,
    public route: ActivatedRoute,
    public router: Router,
    public ngZone: NgZone
  ) {}

  ngOnInit() {}

  async select_language(code: string, index: number) {
    this.trans.use(code);
    localStorage.setItem('language', JSON.stringify(index));
    this.website_data.selected_language =
      this.website_data.language_list[index].code;

    window.location.reload();
  }

  async http_post_method_requester(
    api_name: any,
    parameter: any,
    is_loading: boolean,
    is_stop_loading: boolean,
    show_success_toast: boolean,
    show_error_toast: boolean
  ) {
    this.loader_service.loading = is_loading;

    if (Date.now() - this.api_call_time > 3600000) {
      location.reload();
    }

    let headers: HttpHeaders = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('type', '7')
      .set('is_main_website', 'false');
    let user: any = localStorage.getItem('user');
    if (user) {
      user = JSON.parse(user);
      headers = new HttpHeaders()
        .set('content-type', 'application/json')
        .set('Access-Control-Allow-Origin', '*')
        .set('type', '7')
        .set('is_main_website', 'false')
        .set('id', user._id.toString())
        .set('server_token', user.server_token.toString());
    }

    var res_data = await this.http
      .post<any>(this.base_url + api_name, parameter, { headers: headers })
      .toPromise();

    this.api_call_time = Date.now();
    console.log(this.api_call_time);

    if (is_stop_loading) {
      this.loader_service.loading = false;
    }
    if (res_data.success) {
      res_data.success_message = this.success_message[res_data.message];

      if (show_success_toast) {
        this.onSuccess(res_data.message);
      }
    } else {
      this.loader_service.loading = false;
      if (res_data.error_code === 999) {
        // this.authService.signOut()
      }
      res_data.error_message = this.error_message[res_data.error_code];
      if (show_error_toast) {
        this.onError(res_data.error_code);
      }
    }
    return res_data;
  }

  onError(message: string): void {
    var message_string = this.error_message[message];
    if (message_string) {
      this.toastr.error(this.error_message[message], '');
    }
  }

  onSuccess(message: string): void {
    var message_string = this.success_message[message];
    if (message_string) {
      this.toastr.success(this.success_message[message], '');
      // this.toastr.custom('<span>' + this.success_message[message] + '</span>', null, {enableHTML: true, toastLife: 5000});
    }
  }

  async http_get_method_requester(
    api_name: any,
    parameter: JSON,
    is_loading: boolean,
    is_stop_loading: boolean,
    show_success_toast: boolean,
    show_error_toast: boolean
  ) {
    return await this.http.get<any>(this.base_url + api_name).toPromise();
  }

  number_validation(evt: any) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      if (charCode == 46) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  downloadImage(image_url: string) {
    return this.image_base_url + image_url;
  }

  downloadResizedImage(
    image_url: string,
    height: number,
    width: number,
    image_type: string
  ) {
    return height && width
      ? this.base_url +
          '/resize_image?width=' +
          width +
          '&format=jpeg' +
          '&height=' +
          height +
          '&image=' +
          this.image_base_url +
          image_url
      : null;
  }

  show_default_image(event: any, image: string) {
    event.target.src = image;
  }

  pad2(number: number) {
    return (number < 10 ? '0' : '') + number;
  }

  fire_observable() {
    this.common_data.session_observable.next(true);
  }

  generatorRandomChar(length: number) {
    var token = '';
    var possible = 'abcdefghijklmnopqrstuvwxyz0123456789-';
    for (var i = 0; i < length; i++)
      token += possible.charAt(Math.floor(Math.random() * possible.length));
    return token;
  }

  get_image(
    image_url: string,
    height: any = null,
    width: any = null,
    image_type: string = 'jpg'
  ) {
    return (
      this.base_url +
      'resize_image?&width=' +
      width +
      '&height=' +
      height +
      '&format=' +
      image_type +
      '&image=' +
      this.image_base_url +
      image_url
    );
  }
}
