<div class="pro-cont-detail order-page-tab">
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
    <li [ngbNavItem]="1">
      <a ngbNavLink>{{ helper.title.commandes_en_cours | translate }}</a>
      <ng-template ngbNavContent>
        <div class="order-page-list">
          <ul>
            <li *ngFor="let order of current_order_list; let last = last">
              <h5>
                {{
                  order.estimated_time_for_delivery_order | date : "dd MMM yyyy"
                }}
              </h5>
              <div
                class="o-p-list-details d-flex align-items-center"
                (click)="go_to_order_detail(order._id)"
              >
                <div class="o-images">
                  <img
                    src="{{
                      order.store_image_url
                        ? helper.get_image(order.store_image_url)
                        : helper.website_data.detail.site_logo
                    }}"
                    alt=""
                  />
                </div>
                <div class="o-content">
                  <div class="o-name d-flex align-items-start">
                    <div class="d-flex flex-column">
                      <h4>{{ order.store_name }}</h4>
                      <span class="o-number"
                        >{{ helper.text.order_no | translate }} #{{
                          order.unique_id
                        }}</span
                      >
                    </div>
                    <div class="ml-auto">
                      <span class="o-price">
                        {{ order.currency }}{{ order.total.toFixed(2) }}-
                      </span>
                    </div>
                  </div>
                  <div class="o-time d-flex align-items-center">
                    <div class="d-flex flex-column">
                      <span class="o-p-time">{{
                        order.estimated_time_for_delivery_order
                          | date : helper.website_data.time_format
                      }}</span>
                    </div>
                    <div class="ml-auto">
                      <div [ngSwitch]="order.order_status">
                        <a
                          *ngSwitchCase="
                            order.delivery_status <
                            helper.ORDER_STATE.WAITING_FOR_ACCEPT_STORE
                          "
                          class="o-status"
                          >{{ helper.status.waiting_for_accept | translate }}</a
                        >
                        <a
                          *ngSwitchCase="helper.ORDER_STATE.STORE_ACCEPTED"
                          class="o-status"
                          >{{ helper.status.accepted | translate }}</a
                        >
                        <a
                          *ngSwitchCase="
                            helper.ORDER_STATE.STORE_PREPARING_ORDER
                          "
                          class="o-status"
                          >{{
                            helper.status.start_preparing_order | translate
                          }}</a
                        >
                        <a
                          *ngSwitchCase="helper.ORDER_STATE.OREDER_READY"
                          class="o-status"
                          >{{ helper.status.order_ready | translate }}</a
                        >
                      </div>
                      <div [ngSwitch]="order.delivery_status">
                        <a
                          *ngSwitchCase="
                            helper.ORDER_STATE.WAITING_FOR_DELIVERY_MAN
                          "
                          class="o-status"
                        >
                          <span
                            *ngIf="
                              order.order_status ==
                              helper.ORDER_STATE.OREDER_READY
                            "
                            >{{ helper.status.order_ready | translate }}</span
                          >
                          <span
                            *ngIf="
                              order.order_status !==
                              helper.ORDER_STATE.OREDER_READY
                            "
                            >{{
                              helper.status.order_being_prepared | translate
                            }}</span
                          >
                        </a>
                        <a
                          *ngSwitchCase="
                            helper.ORDER_STATE.DELIVERY_MAN_ACCEPTED
                          "
                          class="o-status"
                        >
                          <span
                            *ngIf="
                              order.order_status ==
                              helper.ORDER_STATE.OREDER_READY
                            "
                            >{{ helper.status.order_ready | translate }}</span
                          >
                          <span
                            *ngIf="
                              order.order_status !==
                              helper.ORDER_STATE.OREDER_READY
                            "
                            >{{
                              helper.status.order_being_prepared | translate
                            }}</span
                          >
                        </a>
                        <a
                          *ngSwitchCase="
                            helper.ORDER_STATE.DELIVERY_MAN_REJECTED
                          "
                          class="o-status"
                        >
                          <span
                            *ngIf="
                              order.order_status ==
                              helper.ORDER_STATE.OREDER_READY
                            "
                            >{{ helper.status.order_ready | translate }}</span
                          >
                          <span
                            *ngIf="
                              order.order_status !==
                              helper.ORDER_STATE.OREDER_READY
                            "
                            >{{
                              helper.status.order_being_prepared | translate
                            }}</span
                          >
                        </a>
                        <a
                          *ngSwitchCase="
                            helper.ORDER_STATE.NO_DELIVERY_MAN_FOUND
                          "
                          class="o-status"
                        >
                          <span
                            *ngIf="
                              order.order_status ==
                              helper.ORDER_STATE.OREDER_READY
                            "
                            >{{ helper.status.order_ready | translate }}</span
                          >
                          <span
                            *ngIf="
                              order.order_status !==
                              helper.ORDER_STATE.OREDER_READY
                            "
                            >{{
                              helper.status.order_being_prepared | translate
                            }}</span
                          >
                        </a>
                        <a
                          *ngSwitchCase="
                            helper.ORDER_STATE.DELIVERY_MAN_CANCELLED
                          "
                          class="o-status"
                        >
                          <span
                            *ngIf="
                              order.order_status ==
                              helper.ORDER_STATE.OREDER_READY
                            "
                            >{{ helper.status.order_ready | translate }}</span
                          >
                          <span
                            *ngIf="
                              order.order_status !==
                              helper.ORDER_STATE.OREDER_READY
                            "
                            >{{
                              helper.status.order_being_prepared | translate
                            }}</span
                          >
                        </a>
                        <a
                          *ngSwitchCase="helper.ORDER_STATE.DELIVERY_MAN_COMING"
                          class="o-status"
                        >
                          <span
                            *ngIf="
                              order.order_status ==
                              helper.ORDER_STATE.OREDER_READY
                            "
                            >{{ helper.status.order_ready | translate }}</span
                          >
                          <span
                            *ngIf="
                              order.order_status !==
                              helper.ORDER_STATE.OREDER_READY
                            "
                            >{{
                              helper.status.order_being_prepared | translate
                            }}</span
                          >
                        </a>
                        <a
                          *ngSwitchCase="
                            helper.ORDER_STATE.DELIVERY_MAN_ARRIVED
                          "
                          class="o-status"
                        >
                          <span
                            *ngIf="
                              order.order_status ==
                              helper.ORDER_STATE.OREDER_READY
                            "
                            >{{ helper.status.order_ready | translate }}</span
                          >
                          <span
                            *ngIf="
                              order.order_status !==
                              helper.ORDER_STATE.OREDER_READY
                            "
                            >{{
                              helper.status.order_being_prepared | translate
                            }}</span
                          >
                        </a>
                        <a
                          *ngSwitchCase="
                            helper.ORDER_STATE.DELIVERY_MAN_PICKED_ORDER
                          "
                          class="o-status"
                        >
                          <span
                            *ngIf="
                              order.order_status ==
                              helper.ORDER_STATE.OREDER_READY
                            "
                            >{{ helper.status.order_ready | translate }}</span
                          >
                          <span
                            *ngIf="
                              order.order_status !==
                              helper.ORDER_STATE.OREDER_READY
                            "
                            >{{
                              helper.status.order_being_prepared | translate
                            }}</span
                          >
                        </a>
                        <a
                          *ngSwitchCase="
                            helper.ORDER_STATE.DELIVERY_MAN_STARTED_DELIVERY
                          "
                          class="o-status"
                          >{{
                            helper.status.started_for_delivery | translate
                          }}</a
                        >
                        <a
                          *ngSwitchCase="
                            helper.ORDER_STATE
                              .DELIVERY_MAN_ARRIVED_AT_DESTINATION
                          "
                          class="o-status"
                          >{{
                            helper.status.arrived_at_destination | translate
                          }}</a
                        >
                        <a
                          *ngSwitchCase="
                            helper.ORDER_STATE.DELIVERY_MAN_COMPLETE_DELIVERY
                          "
                          class="o-status"
                          >{{ helper.status.delivered_ordered | translate }}</a
                        >
                        <a
                          *ngSwitchCase="helper.ORDER_STATE.ORDER_COMPLETED"
                          class="o-status"
                          >{{ helper.status.order_completed | translate }}</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink>{{ helper.title.historique | translate }}</a>
      <ng-template ngbNavContent>
        <div class="order-page-list">
          <ul>
            <li *ngFor="let order of order_history; let last = last">
              <h5>{{ order.completed_at | date : "dd MMM yyyy" }}</h5>
              <div
                class="o-p-list-details d-flex align-items-center"
                (click)="go_to_order_detail(order._id)"
              >
                <div class="o-images">
                  <img
                    src="{{
                      order.store_image_url
                        ? helper.get_image(order.store_image_url)
                        : helper.website_data.detail.site_logo
                    }}"
                    alt=""
                  />
                </div>
                <div class="o-content">
                  <div class="o-name d-flex align-items-start">
                    <div class="d-flex flex-column">
                      <h4>{{ order.store_detail.name }}</h4>
                      <span class="o-number"
                        >{{ helper.text.order_no | translate }} #{{
                          order.unique_id
                        }}</span
                      >
                    </div>
                    <div class="ml-auto">
                      <span class="o-price">
                        {{ order.currency }}{{ order.total.toFixed(2) }}-
                      </span>
                    </div>
                  </div>
                  <div class="o-time d-flex align-items-center">
                    <div class="d-flex flex-column">
                      <span class="o-p-time">{{
                        order.completed_at
                          | date : helper.website_data.time_format
                      }}</span>
                    </div>
                    <div class="ml-auto" [ngSwitch]="order.order_status">
                      <a
                        *ngSwitchCase="
                          helper.ORDER_STATE.DELIVERY_MAN_COMPLETE_DELIVERY
                        "
                        class="o-status"
                        >{{ helper.status.delivered_ordered | translate }}</a
                      >
                      <a
                        *ngSwitchCase="helper.ORDER_STATE.STORE_REJECTED"
                        class="o-status"
                        >{{ helper.status.rejected | translate }}</a
                      >
                      <a
                        *ngSwitchCase="helper.ORDER_STATE.STORE_CANCELLED"
                        class="o-status"
                        >{{ helper.status.store_cancelled | translate }}</a
                      >
                      <a
                        *ngSwitchCase="helper.ORDER_STATE.NO_DELIVERY_MAN_FOUND"
                        class="o-status"
                        >{{
                          helper.status.no_delivery_man_found | translate
                        }}</a
                      >
                      <a
                        *ngSwitchCase="helper.ORDER_STATE.DELIVERY_MAN_REJECTED"
                        class="o-status"
                        >{{
                          helper.status.delivery_man_rejected | translate
                        }}</a
                      >
                      <a
                        *ngSwitchCase="
                          helper.ORDER_STATE.DELIVERY_MAN_CANCELLED
                        "
                        class="o-status"
                        >{{
                          helper.status.delivery_man_cancelled | translate
                        }}</a
                      >
                      <a
                        *ngSwitchCase="helper.ORDER_STATE.CANCELED_BY_USER"
                        class="o-status"
                        >{{ helper.status.cancelled | translate }}</a
                      >
                      <a
                        *ngSwitchCase="
                          helper.ORDER_STATE.STORE_CANCELLED_REQUEST
                        "
                        class="o-status"
                        >{{ helper.status.cancelled_request | translate }}</a
                      >
                      <a
                        *ngSwitchCase="helper.ORDER_STATE.ORDER_COMPLETED"
                        class="o-status"
                        >{{ helper.status.completed | translate }}</a
                      >
                      <a
                        *ngSwitchCase="helper.ORDER_STATE.ADMIN_CANCELLED"
                        class="o-status"
                        >{{ helper.status.cancelled_by_admin | translate }}</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
