import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class WebsiteData {
  detail: any = {
    site_color: '',
    site_desc: '',
    site_logo: '',
    store_id: '',
    city_id: '',
    name: '',
    address: '',
    phone: '',
    country_phone_code: '',
  };

  public selected_language: any;
  public time_format: any = 'HH:mm';
  public language_list: any[] = [
    {
      code: 'fr',
      name: 'French',
    },
    {
      code: 'en',
      name: 'English',
    },
  ];

  public constructor() {}
}
